import React, { useState, useEffect } from 'react'
import Tooltip from 'src/components/Tooltip'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import exploreMercadoJson from './exploreMercado.json'
import exploreMercadoMobileJson from './exploreMercadoMobile.json'
import * as S from './style'
import { WIDTH_MD } from 'src/utils/breakpoints'

type ItemsProps = {
  id: number;
  title: string;
  value: string;
  tooltip?: string;
}

type InfoProps = {
  id: number;
  title: string;
  description: string;
  items: ItemsProps[];
}

const ExploreMercado = () => {
  const width = useWidth()

  const [ opened, setOpened ] = useState([ false, false, false ])
  const [ tooltip, setTooltip ] = useState([ false, false, false, false ])

  const data = width < 768 ? exploreMercadoMobileJson : exploreMercadoJson

  function toggleAccordeon (index: number) {
    const newOpenedState = new Array(3).fill(false)
    newOpenedState[index] = true
    setOpened([ ...newOpenedState ])
    if (opened[index]) {
      setOpened([ false ])
    }
  }

  function setTooltipEnter (index: number) {
    tooltip[index] = true
    setTooltip([ ...tooltip ])
  }

  function setTooltipLeave (index: number) {
    tooltip[index] = false
    setTooltip([ ...tooltip ])
  }

  useEffect(() => {
    const onScroll = () => setTooltip([ false, false, false, false ])

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <section>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-md-center mb-4 mb-lg-3 mt-xl-5'>
              {exploreMercadoJson.title}
            </h2>
            <p
              className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--500 text-md-center mb-4 mb-lg-5'
              dangerouslySetInnerHTML={{ __html: exploreMercadoJson.description }}
            />
          </div>
          <div className='col-12'>
            {
              data.infos.map((item: InfoProps, index: number) => (
                <S.Accordeon key={item.id} className={opened[index] ? 'active' : 'cursor-pointer'}>
                  <S.AccordeonTitle onClick={() => toggleAccordeon(index)}>
                    <h3
                      className='fs-14 fs-md-16 fs-lg-20 lh-16 lh-md-20 lh-lg-22 text-grayscale--500 fw-700 mb-0'
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <div className={`${opened[index] ? 'arrow-active' : ''} d-flex justify-content-center align-items-center`}>
                      <OrangeIcon icon='chevron-down' color='#FF7A00' size='SM' />
                    </div>
                  </S.AccordeonTitle>
                  <hr style={{ borderTop: '1px dashed #DEDFE4' }} />
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-lg-20 lh-16 lh-xl-22 text-grayscale--400'>{item.description}</p>
                  <S.AccordeonContent className={item.id === 0 ? 'd-flex justify-content-between' : ''}>
                    {
                      item.items.map((values: ItemsProps) => (
                        <S.AccordeonContent key={values.id}>
                          <div className={item.id !== 0 ? 'd-flex justify-content-between justify-content-md-start' : ''}>
                            <div className='d-flex align-items-center mr-3 mb-3'>
                              <p
                                className='fs-14 fs-lg-16 fs-xl-18 lh-lg-20 lh-16 lh-xl-22 text-grayscale--500 mb-0'
                                dangerouslySetInnerHTML={{ __html: values.title }}
                              />
                              {
                                values.tooltip && (
                                  <S.TooltipWrapper
                                    onMouseEnter={() => width > 768 ? setTooltipEnter(values.id) : ''}
                                    onMouseLeave={() => width > 768 ? setTooltipLeave(values.id) : ''}
                                    onClick={() => tooltip[values.id] ? setTooltipLeave(values.id) : setTooltipEnter(values.id)}
                                  >
                                    <OrangeIcon icon='help' size='SM' color='#FF7A00' className='ml-1 ml-md-2' />
                                    {
                                      tooltip[values.id] && (
                                        <>
                                          {width >= WIDTH_MD &&
                                            <Tooltip className='position' title={values.title} content={values.tooltip} />
                                          }
                                          <div className='d-md-none'>
                                            <S.DarkBG />
                                            <S.BottomSheet>
                                              <S.Handler />
                                              <S.Title>{values.title}</S.Title>
                                              <S.Description dangerouslySetInnerHTML={{ __html: values.tooltip }} />
                                              <S.Button>Entendi</S.Button>
                                            </S.BottomSheet>
                                          </div>
                                        </>
                                      )
                                    }
                                  </S.TooltipWrapper>
                                )
                              }
                              {item.id !== 0 && <p className='fs-14 fs-lg-16 fs-xl-18 lh-lg-20 lh-16 lh-xl-22 text-grayscale--500 mb-0 ml-md-4 mt-1 fw-600 d-none d-md-block'>{values.value}</p>}
                            </div>
                            {item.id === 0 && <p className='fs-14 fs-lg-16 fs-xl-18 lh-lg-20 lh-16 lh-xl-22 text-grayscale--500 mb-0 fw-600 d-none d-md-block'>{values.value}</p>}
                            <p className='fs-14 fs-lg-16 fs-xl-18 lh-lg-20 lh-16 lh-xl-22 text-grayscale--500 mb-0 fw-600 d-block d-md-none'>{values.value}</p>
                          </div>
                        </S.AccordeonContent>
                      ))
                    }
                  </S.AccordeonContent>
                </S.Accordeon>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExploreMercado
