import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const DataContent = styled.div`
  a { 
    cursor: pointer;
  }
`

export const ButtonText = styled.a`
  color: ${orange.extra};
  font-weight: bold;
  cursor: pointer;
`
