import React from 'react'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import acompanheJson from './acompanhe.json'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

const Acompanhe = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 pl-lg-3'>
            <Img fluid={data.HomeBrokerAcompanhe.fluid} />
          </div>
          <div className='col-12 col-md-6 col-xl-5 d-flex flex-column justify-content-center pr-md-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 mt-4 mt-md-0'>{acompanheJson.title}</h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--500 my-4 my-md-3'>{acompanheJson.description}</p>
            <a
              href='https://blog.inter.co/ir-investimentos-no-exterior'
              role='button'
              target='_blank'
              rel='noreferrer'
              className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mt-lg-4 mb-md-0'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: acompanheJson.title,
                  element_action: 'click button',
                  element_name: acompanheJson.button,
                  redirect_url: 'https://blog.inter.co/ir-investimentos-no-exterior',
                })
              }}
            >
              {acompanheJson.button}
            </a>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Acompanhe
