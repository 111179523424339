import React from 'react'
import FAQ from 'src/components/StructuredData/FAQ'
import { structuredData } from '../../pageContext.json'
import { FAQSection } from './style'

const FaqComponent = () => {
  return (
    <FAQSection className='py-5' id='perguntas-frequentes'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500'>
              Perguntas frequentes
            </h2>
            <div>
              <p className='d-none d-xl-block fs-18 lh-22 text-grayscale--500'>
                Veja se podemos te ajudar com essas perguntas frequentes.
              </p>
            </div>
          </div>
          <FAQ
            data={structuredData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            styles='summary-content px-0'
          />
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqComponent
