import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      InvestirAtivosEUA: imageSharp(fluid: { originalName: { regex: "/home-broker-international-invest-eua/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }                
      HomeBrokerAcompanhe: imageSharp(fluid: { originalName: { regex: "/home-broker-international-acompanhe/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }  
      ApexClearingLogo: imageSharp(fluid: { originalName: { regex: "/apex-clearing-logo-hero/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AdvantagesImage: imageSharp(fluid: { originalName: { regex: "/advantages-image/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }                
    }
  `)

  return pageQuery
}

export default PageQuery
