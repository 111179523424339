import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import * as S from './style'

import headerJson from './header.json'
import usePageQuery from '../../pageQuery'

type modalProps = {
  setIsOpen: Function;
}

const Header = ({ setIsOpen }: modalProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper
      className='pt-4 pb-5 pt-lg-5'
      id='invista-diretamente-em-um-dos-maiores-mercados'
    >
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 pb-5 d-md-none'>
            <Img
              fluid={data.ApexClearingLogo.fluid}
              alt='Uma rapaz sentado em um banco sorrindo acessando seu celular'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <div className='bread d-flex'>
              <Link
                to='/'
                className='d-md-inline'
              ><OrangeIcon icon='home' color='#6A6E81' size='MD' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-block ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1 className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--500 fw-600 mb-3 mt-3'>{headerJson.title}</h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 mb-3'>{headerJson.description}</p>
            {
              width > 768 ? (
                <button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: headerJson.title,
                      element_action: 'click button',
                      element_name: headerJson.button,
                    })
                  }}
                  title='Investir no exterior'
                  className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mb-4 mt-3 mb-md-0'
                > {headerJson.button}
                </button>
              ) : (
                <a
                  href='https://intergo.app/93deb036'
                  className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mb-md-0'
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: headerJson.title,
                      element_action: 'click button',
                      element_name: headerJson.button,
                      redirect_url: 'https://intergo.app/93deb036',
                    })
                  }}
                > {headerJson.button}
                </a>
              )
            }
          </div>
          <div className='col-6 col-xl-5 offset-xl-2 d-md-none d-none d-md-block'>
            <Img
              fluid={data.ApexClearingLogo.fluid}
              alt='Uma rapaz sentado em um banco sorrindo acessando seu celular'
              className='float-lg-right'
            />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Header
