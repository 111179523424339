import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  position: absolute;
  top: 30px;
  background-color: ${grayscale[500]};
  height: fit-content;
  padding: 15px;
  border-radius: 8px;
  width: 130px;
  right: -40px;
  z-index: 99;

  @media ${device.tablet} {
    width: 240px;
  }
  
  @media ${device.desktopLG} {
    width: 300px;
    left: 0;
  }

  &.position {
    @media ${device.tablet} {
      right: -127px;
    }
    @media ${device.desktopLG} {
      left: -53px;
    }
  }
`
