import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import ScrollTo from 'src/components/ScrollTo'

import * as S from './style'

const Disclaimer = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='py-5'>
      <div className='container pt-lg-3'>
        <div className='row'>
          <div className='col-12 col-md-1 d-flex align-items-center mb-4 mb-md-0 pl-md-0 pl-lg-3'>
            <OrangeIcon icon='attention' size='MD' color='#FF7A00' />
          </div>
          <div className='col-12 col-md-11 fs-12 lh-14 pl-2 pl-md-0'>
            <p>
              A Inter&Co. Securities, LLC (“Inter Securities”), entidade estrangeira constituída e autorizada nos Estados Unidos da América (“EUA”) sob o número de registro
              CRD#: 317278/SEC#: 8-70985, não está autorizada pela Comissão de Valores Mobiliários (“CVM”) a oferecer diretamente valores mobiliários a investidores residentes
              no Brasil, de modo que seus serviços não estão registrados e não estão sujeitos à supervisão da CVM ou qualquer outro órgão regulador brasileiro.
            </p>
            <p>
              Nesse sentido, nos termos do Parecer de Orientação CVM nº 33/2005, a Inter Securities contratou a Inter Distribuidora de Títulos e Valores Mobiliários Ltda.
              (“Inter DTVM” e “Inter Invest”), entidade brasileira devidamente autorizada a funcionar pelo Banco Central do Brasil e integrante do sistema de distribuição
              de valores mobiliários brasileiro credenciada pela CVM, como sua intermediária, para possibilitar o acesso dos investidores brasileiros aos serviços prestados
              pela Inter Securities. A Inter DTVM figura como mero intermediário da Inter Securities, responsável pela captação, procedimentos de cadastro e onboarding do Cliente,
              inexistindo qualquer responsabilidade da sua parte perante os atos praticados dentro da plataforma. A Inter DTVM pode receber remuneração da Inter Securities pelos serviços prestados.
            </p>
            <p>
              A Inter DTVM diligencia para assegurar que a Inter Securities está regularmente constituída, existente e autorizada a funcionar nos EUA, e é registrada perante os reguladores
              competentes norte-americanos, a saber: a Financial Industry Regulatory Authority
              <a
                href='https://brokercheck.finra.org/firm/summary/317278'
                title='Financial Industry Regulatory Authority'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: '(FINRA)',
                    redirect_url: 'https://brokercheck.finra.org/firm/summary/317278',
                  })
                }}
              > (FINRA)
              </a> e a Securities Investor Protection Corporation
              <a
                href='https://www.sipc.org/'
                title='Securities Investor Protection Corporation'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: '(SIPC)',
                    redirect_url: 'https://www.sipc.org/',
                  })
                }}
              > (SIPC)
              </a>.
            </p>
            <p>
              Os serviços de liquidação são fornecidos pela Apex Clearing Corporation, membra do SIPC, e os ativos são protegidos pelo SIPC, no caso de falência ou liquidação
              da corretora, no limite de até U$500.000 em títulos, dos quais U$250.000 podem ser em dinheiro. Para mais informações sobre o SIPC, acesse o link:
              <a
                href='https://www.sipc.org/about-sipc/'
                title='Para mais informações sobre o SIPC, acesse o link'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'https://www.sipc.org/about-sipc/',
                    redirect_url: 'https://www.sipc.org/about-sipc/',
                  })
                }}
              > https://www.sipc.org/about-sipc/
              </a>
            </p>
            <p>
              Investir nos ativos negociados no exterior através da Inter Securities envolve risco, incluindo possível perda do valor principal. Tais ativos não possuem seguro do
              Federal Deposit Insurance Corporation (FDIC), não possuem garantia bancária e estão sujeitos a perda de valor. Os investidores devem considerar cuidadosamente seus
              objetivos e riscos de investimento antes de investir. O preço de um determinado título, ativo e/ou valor mobiliário pode aumentar ou diminuir com base nas condições
              do mercado e os clientes podem perder dinheiro, incluindo seu investimento original. A rentabilidade obtida no passado não representa garantia de resultados futuros.
            </p>
            <p>
              Para maiores informações sobre as proteções disponíveis para o investidor na jurisdição estrangeira,
              <a
                href='https://ajuda.inter.co/investimentos/e-seguro-realizar-investimentos-na-plataforma-inter-securities/'
                className='text-orange--extra'
                title='Informações sobre proteções disponíveis'
                target='_blank' rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'clique aqui',
                    redirect_url: 'https://ajuda.inter.co/investimentos/e-seguro-realizar-investimentos-na-plataforma-inter-securities/',
                  })
                }}
              > clique aqui
              </a>.
            </p>
            <p>
              Operações com ativos estrangeiros são compatíveis somente com investidores que possuam perfil arrojado junto a Inter DTVM.
            </p>
            <p>
              As decisões de investimento cabem exclusivamente ao investidor. É recomendada a leitura dos prospectos, regulamentos, editais e demais documentos descritivos
              dos ativos antes de investir, com especial atenção ao detalhamento do risco do investimento.
            </p>
            <p>
              Por se tratar de negociação de valores mobiliários norte americano, recomenda-se que o investidor tenha conhecimento da língua local, bem como das regulamentações
              sobre o tema, incluindo a estrutura financeira do mercado norte-americano, sua legislação, e que tenha conhecimento de eventuais medidas protetivas frente
              a jurisdição estrangeira. Para mais informações a respeito dos riscos inerentes aos investimentos realizados no exterior,
              <a
                href='https://ajuda.inter.co/investimentos/quais-sao-os-principais-riscos-ao-investir-no-exterior'
                title='Para mais informações a respeito dos riscos inerentes, clique aqui.'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'clique aqui.',
                    redirect_url: 'https://ajuda.inter.co/investimentos/quais-sao-os-principais-riscos-ao-investir-no-exterior',
                  })
                }}
              > clique aqui.
              </a>
            </p>
            <p>
              Os ativos negociados por meio da Inter Securities, no exterior, são transacionados em dólar norte-americano. Quando um investimento é negociado em uma moeda que não
              seja a moeda do investidor, as mudanças nas taxas de câmbio podem ter um efeito adverso no valor, preço ou receita derivada do investimento.
            </p>
            <p>
              O cumprimento de obrigações específicas perante a legislação norte-americana em relação aos ativos, bem como o custo da operação e de qualquer
              obrigação tributária, cambial e/ou outras, é de competência exclusiva do investidor.
            </p>
            <p>
              Os custos e prazos para a transferência internacional de recursos, bem como para manutenção da conta e para negociação de valores
              mobiliários pela Inter Securities podem ser verificados
              <ScrollTo
                to='#invista-diretamente-em-um-dos-maiores-mercados'
                title='Os valores mobiliários pela Inter Securities podem ser verificados aqui'
                className='text-orange--extra'
                section='dobra_09'
                sectionName='Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)'
                elementName='aqui'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'aqui',
                    redirect_url: window.location.href + '#invista-diretamente-em-um-dos-maiores-mercados',
                  })
                }}
              > aqui,
              </ScrollTo> observando que tais custos são atualizados de tempos em tempos e estão sempre disponíveis para consulta dos clientes.
            </p>
            <p>
              Informamos que as pessoas físicas ou jurídicas residentes, domiciliadas ou com sede no Brasil, que detenham, no exterior, recursos acima de determinado montante
              devem realizar declaração do CBE (capitais brasileiros no exterior) ao Banco Central do Brasil. Mais informações podem ser verificadas neste link:
              <a
                href='https://www.bcb.gov.br/estabilidadefinanceira/cbe'
                title='Mais informações podem ser verificadas neste link:'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'https://www.bcb.gov.br/estabilidadefinanceira/cbe.',
                    redirect_url: 'https://www.bcb.gov.br/estabilidadefinanceira/cbe.',
                  })
                }}
              > https://www.bcb.gov.br/estabilidadefinanceira/cbe.
              </a>
            </p>
            <p>
              As informações aqui fornecidas são de natureza geral e não pretendem substituir um aconselhamento individualizado e específico sobre impostos,
              leis ou planejamento de investimentos. Os investidores devem se informar e, quando apropriado, consultar um profissional sobre possíveis
              consequências fiscais associadas à oferta, compra, posse ou alienação de ações de acordo com as leis de seu país de cidadania, residência,
              domicílio ou incorporação. Alterações legislativas, administrativas ou judiciais podem modificar as consequências tributárias e, como é
              o caso de qualquer investimento, não há garantia de que a posição fiscal ou a posição tributária no momento em que um investimento é realizado permanecerá indefinidamente.
            </p>
            <p>
              Para mais informações sobre impostos, clique
              <a
                href='https://ajuda.inter.co/investimentos/como-funciona-a-tributacao-sobre-o-ganho-de-capital-na-alienacao-gcap/'
                title='Para mais informações sobre impostos, clique aqui.'
                target='_blank' rel='noreferrer'
                className='text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Disclaimer: A Inter&Co. Securities, LLC (“Inter Securities”)',
                    element_action: 'click button',
                    element_name: 'aqui.',
                    redirect_url: 'https://ajuda.inter.co/investimentos/como-funciona-a-tributacao-sobre-o-ganho-de-capital-na-alienacao-gcap/',
                  })
                }}
              > aqui.
              </a>
            </p>
            <p>
              Nada neste site deve ser considerado uma oferta, recomendação, solicitação de oferta ou conselho para comprar ou vender qualquer título, ativo ou
              valor mobiliário. Os serviços prestados pela Inter DTVM não devem ser entendidos como oferta pública de valores mobiliários direcionada a investidores
              brasileiros. Embora a Inter DTVM tenha sido contratada para prestar serviços à Inter Securities, a Inter Securities oferece seus serviços
              exclusivamente nos Estados Unidos da América e sob as normas e regulamentos aplicáveis naquele país. Por se tratar de um investimento
              realizado diretamente nos Estados Unidos da América por meio da Inter Securities, os valores mobiliários disponíveis na plataforma
              Inter Securities não foram registrados nem estão sujeitos à supervisão da CVM ou de outro órgão regulador brasileiro, de modo que o
              investidor deve ter conhecimento do idioma e regulamentos aplicáveis dos EUA.
            </p>
            <p className='mb-0'>
              Os tempos de resposta do sistema e de acesso à conta, assim como toda comunicação através de rede mundial de computadores, estão sujeitos a demoras,
              interrupções ou atrasos, podendo impedir ou prejudicar o envio de ordens ou a recepção de informações atualizadas, o que também pode representar
              um risco ao investidor. Este material não pode ser reproduzido, distribuído ou publicado por qualquer pessoa, para quaisquer fins sem autorização.
            </p>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Disclaimer
