import React, { ChangeEvent, useEffect, useState } from 'react'
import slugify from 'slugify'
import iconClear from '../../../assets/images/shared/icon-clear.png'

import SummaryDetails from 'components/UI/SummaryDetails'
import MarkdownIcon from 'components/UI/MarkdownIcon'

import { FAQSearch, SearchClose } from './style'

type FaqItemProps = {
  question: string;
  answer: string;
};

type FaqProps = {
  styles: string;
  data: FaqItemProps[];
  search: boolean;
  id?: string;
  columns?: {
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

const defaultCols = { sm: 1, md: 1, lg: 2, xl: 2 }

const FAQ = ({ styles, data, search, columns, id }: FaqProps) => {
  const [ value, setValue ] = useState('')
  const [ questions, setQuestions ] = useState(data)
  const questionNotFoundMessage = (
    <div className='col-12 my-4'>
      <p
        style={{ wordBreak: 'break-all' }}
        className='question-not-found text-grayscale--500'
      >
        Não foi possível encontrar nenhuma pergunta para:{' '}
        <span className='fw-600'>{value}</span>
      </p>
    </div>
  )
  const cols = { ...defaultCols, ...columns }

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setValue(valueInput)
    if (valueInput.length >= 3) {
      const valueSplitted = valueInput.trim().split(/\s/)
      const regexItemValue = new RegExp(`(${valueSplitted.join('|')})`, 'gi')
      const filteredQuestions = data
        .filter(
          (item: FaqItemProps) =>
            item.question.match(regexItemValue) ||
            item.answer.match(regexItemValue),
        )
        .map((filteredItem: FaqItemProps) => ({
          question: filteredItem.question,
          answer: filteredItem.answer.replace(
            regexItemValue,
            '<strong class="text-orange--base">$1</strong>',
          ),
        }))
      setQuestions([ ...filteredQuestions ])
    } else {
      setQuestions(data)
    }
  }

  useEffect(() => {
    setQuestions(data)
  }, [ data ])

  const closeSearch = () => {
    setValue('')
    setQuestions(data)
  }

  return (
    <>
      {search && (
        <div className='col-12 mt-3 mb-4 position-relative'>
          <FAQSearch
            type='text'
            value={value}
            id={value}
            placeholder='Pesquisar'
            onChange={handleChange}
            className='search-input px-4'
            maxLength={150}
          />
          {value === '' ? (
            <MarkdownIcon
              width={16}
              height={16}
              directory='v2'
              icon='navigation/search'
              color='white'
            />
          ) : (
            <SearchClose onClick={closeSearch} title='Limpar'>
              <img src={iconClear} />
            </SearchClose>
          )}
        </div>
      )}
      {questions.length > 0
        ? questions.map((item: FaqItemProps) => (
          <div
            key={item.question}
            id={id}
            className={`col-12 col-sm-${12 / cols.sm} col-md-${12 /
                cols.md} col-lg-${12 / cols.lg} col-xl-${12 / cols.xl}`}
          >
            <SummaryDetails
              id={slugify(item.question)}
              summary={item.question}
              found={value.length >= 3}
            >
              <div
                className={styles}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </SummaryDetails>
          </div>
          ))
        : questionNotFoundMessage}
    </>
  )
}

FAQ.defaultProps = {
  search: true,
  styles: '',
  columns: {},
  className: '',
}

export default FAQ
