import React, { useState, useEffect, memo, useMemo } from 'react'
import slugify from 'slugify'
import { useLocation } from '@reach/router'

import './SummaryDetails.scss'

type summaryDetailsProps = {
  summary: string;
  children?: React.ReactNode;
  className?: string;
  id: string;
  found: boolean;
}

function SummaryDetails ({ summary, children, className, found, id }: summaryDetailsProps) {
  const [ isOpen, setIsOpen ] = useState(false)
  const slug = useMemo(() => slugify(id), [])
  const location = useLocation()

  useEffect(() => {
    setIsOpen(found)
  }, [ found ])

  useEffect(() => {
    const hash = location.hash.substr(1)
    if (hash === slug) setIsOpen(true)
  }, [ location.hash ])

  function toggle (evt: { target: { tagName: string }; preventDefault: () => string }) {
    if (evt.target.tagName !== 'A') {
      evt && evt.preventDefault()
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className='summary-details'>
      <div className={`summary ${className} ${isOpen ? 'summary--open' : ''}`}>
        <div
          className='cursor-pointer summary-content d-flex align-items-center py-4'
          onClick={toggle}
        >
          <div className='col-11 lh-25 px-0 pl-xl-2' data-faq={summary} id={id}>
            <h4 className='fs-16 fs-md-18 lh-20 fw-600 content-title'>{summary}</h4>
          </div>
          <div className='col-1'>
            <div className={`arrow ${isOpen ? 'arrow--open' : ''}`} />
          </div>
        </div>
        <div className={`details ${isOpen ? 'details--open' : 'details--closed'}`}>
          <div className='details-content fs-14 fs-md-16 lh-17 lh-md-19 mt-1 mt-md-3 mt-lg-0 pb-4 pb-md-2 pt-md-0 pb-lg-4'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

SummaryDetails.defaultProps = {
  summary: '',
  children: <div />,
  id: 'summary-details',
}

export default memo<summaryDetailsProps>(SummaryDetails)
