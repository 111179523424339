import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 437px;
  }
  @media ${device.tablet} {
    min-height: 500px;
  }
  @media ${device.desktopXXL} {
    min-height: 648px;
  }
  
  button {
    max-width: 100%;
    width: 100%;
  }

  .bread {
    align-items: center;
    justify-content: start;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
