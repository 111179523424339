import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  background-color: ${grayscale[100]};

  .btn {
    max-width: 456px;
  }
`
