import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const FAQSection = styled.section`
  h4 {
    margin-bottom: 0;
  }
  
  .summary-details {
    .summary {
      .summary-content {
        align-items: center;
        p {
          color: ${grayscale[400]}
        }
        span {
          color: ${grayscale[400]}
        }
      }
    }
  }
 
  .search-input {
    text-align: left !important;
  }
  .icon--navigation {
    right: 40px;
  }
`
