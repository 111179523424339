import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContainerCarousel = styled.section`
  min-height: 738px;

  @media ${device.tablet} {
    min-height: 555px;
  }
  @media ${device.desktopLG} {
    min-height: 660px;
  }
  @media ${device.desktopXL} {
    min-height: 864px;
  }

  .react-multi-carousel-track {
    padding-top: 0;
  }

  .react-multi-carousel-item {
    @media ${device.tablet} {
      align-items: center;
      display: flex;
    }
  }

  .react-multi-carousel-dot-list {
    
    button {
      background: ${orange.extra}
    }

    .react-multi-carousel-dot {
      &--active {
        button {
          background: ${orange.extra}
        }
      }
    }
  }

  .react-multiple-carousel__arrow {

    &--left {
      @media ${device.tablet} {
        left: -5px;
      }
    }
    &--right {
      @media ${device.tablet} {
        right: -3px;
      }
    }
  }
`
