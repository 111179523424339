import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

export const FAQSearch = styled.input`
  background-color: ${grayscale[100]};
  color: ${grayscale[500]};
  font-weight: 700;
  border: none;
  border-radius: ${rounded[3]};
  width: 100%;
  height: 48px;
  font-size: 14px;
  outline: 0;


  ~ svg {
    fill: ${orange.base};
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%) !important;
    position: absolute;
  }
`

export const SearchClose = styled.div`
  float: right;
  position: absolute;
  top: 9px;
  right: 23px;
  cursor: pointer;

  img {
    width: 24px;
  }
`
