import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SocialMediaCard = styled.section`
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  padding: 25px;
  display: flex; 
  justify-content: space-between;
  flex-wrap: wrap;
  width: 49%;
  margin: 10px 0;
  background: white;
`
