import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import carouselData from './carouselData.json'
import { ContainerCarousel } from './styles'

type CarouselDataProps = {
  description: string;
  image: {
    mobile: string;
    desktop: string;
  };
  imageMobile?: string;
  isVideo: boolean;
  step: string;
  title: string;
  text: string;
}

const CarrouselInvestiments = () => {
  const [ imageIndex, setImageIndex ] = useState(0)
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const images = useStaticQuery(graphql`
    query {
      criarContaInvestimentoGlobal: imageSharp(fluid: {originalName: { regex: "/criar-conta-investimento-global/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      faleComBabiInvestimentoGlobal: imageSharp(fluid: {originalName: { regex: "/fale-com-babi-investimento/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeInvestimentosInternacionaisMobile: imageSharp(fluid: {originalName: { regex: "/portabilidade-de-investimentos-internacionais-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeInvestimentosInternacionaisDesktop: imageSharp(fluid: {originalName: { regex: "/portabilidade-de-investimentos-internacionais-desktop/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      envieFormularioInvestimentoGlobal: imageSharp(fluid: {originalName: { regex: "/envie-formulario-investimento-global/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seusInvestimentosComOInter: imageSharp(fluid: {originalName: { regex: "/seus-investimentos-internacionais/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

function changeImage (nextSlide: number) {
  setImageIndex(nextSlide)
}
  return (
    <ContainerCarousel className='py-5 d-flex position-relative align-items-center justify-content-md-between'>
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-5'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(nextSlide: number) => changeImage(nextSlide)}
            >
              {
                carouselData.map((item: CarouselDataProps) => (
                  <div key={item.title}>
                    <div>
                      <p className='fs-14 lh-17 text-grayscale--400 mb-3'>
                        {item.description}
                      </p>
                    </div>
                    <div>
                      <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 text-grayscale--500 fw-600 mb-3'>{item.step}
                      </p>
                      <h4
                        className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'
                      >{item.title}
                      </h4>
                      <p
                        className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 mb-4 mb-md-0'
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                    {item.isVideo && width < WIDTH_MD ? (
                      <div className='col-12 col-md-6 col-xl-3 offset-xl-1 d-md-none'>
                        <Img fluid={images[item.image.mobile].fluid} />
                      </div>
                      ) : (
                        <div className='col-12 col-md-6 col-xl-3 offset-xl-1 d-md-none'>
                          <OpenVideo
                            link='https://www.youtube.com/embed/97-UfClHBT8?&autoplay=1'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_06',
                                section_name: item.title,
                                element_action: 'click video',
                                element_name: 'null',
                                redirect_url: 'https://www.youtube.com/embed/97-UfClHBT8?&autoplay=1',
                              })
                            }}
                          >
                            <Img fluid={images[item.image.desktop].fluid} />
                          </OpenVideo>
                        </div>
                      )}
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 d-none d-md-block cursor-pointer'>
            {!carouselData[imageIndex].isVideo ? (
              <Img fluid={images[carouselData[imageIndex].image.desktop].fluid} />
              ) : (
                <OpenVideo
                  link='https://www.youtube.com/embed/97-UfClHBT8?&autoplay=1'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      section_name: '3 - Assine a carta de portabilidade',
                      element_action: 'click video',
                      element_name: 'null',
                      redirect_url: 'https://www.youtube.com/embed/97-UfClHBT8?&autoplay=1',
                    })
                  }}
                >
                  <Img fluid={images[carouselData[imageIndex].image.desktop].fluid} />
                </OpenVideo>)}
          </div>
        </div>
      </div>
    </ContainerCarousel>
  )
}

export default CarrouselInvestiments
