import React from 'react'
import * as S from './style'

type TooltipProps = {
  title: string;
  content: string;
  className?: string;
}

const Tooltip = ({ title, content, className }: TooltipProps) => {
  return (
    <S.Wrapper className={className}>
      <h4 className='fs-12 lh-14 fw-700 text-white mb-2' dangerouslySetInnerHTML={{ __html: title }} />
      <p className='fs-12 lh-14 text-white mb-0' dangerouslySetInnerHTML={{ __html: content }} />
    </S.Wrapper>
  )
}

export default Tooltip
