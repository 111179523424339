import React, { useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

import { orange } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'

const ToolTip = () => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onClick={() => handleClick()}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(true)}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className='tooltip'>
          <p className='fs-12 lh-15 fw-700 text-white mb-1'>Spread</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>
            <span className='d-block mb-3'>
              Basicamente, o spread cambial é a diferença entre o preço de compra e o preço de venda de uma moeda.
            </span>
            <span className='d-block mb-3'>Para enviar uma remessa, o valor mínimo é de US$ 5,00.</span>
            <span className='d-block'>Valor da taxa:</span>
            <ul className='pl-4'>
              <li>Clientes Digital: 1,5%</li>
              <li>Clientes One: 1,5%</li>
              <li>Clientes Black: 1,25%</li>
              <li>Clientes WIN: 0,99%</li>
            </ul>
          </p>
          <span
            className='d-block fs-12 lh-15 fw-700 text-orange--extra text-right'
            onClick={() => setOpen(false)}
            title='Entendi'
          >Entendi
          </span>
        </Tooltip>
      }
    </>
  )
}

export default ToolTip
