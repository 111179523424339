import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import fiquePorDentroJson from './fiquePorDentro.json'
import * as S from './style'

type ItemProps = {
  id: number;
  title: string;
  icon: string;
  iconTitle?: string;
  link: string;
}

const FiquePorDentro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section>
      <div className='bg-grayscale--100 py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 my-md-4'>
              <div className='d-block d-md-none'>
                <div className='col-12 d-flex flex-column px-0'>
                  <h2 className='fs-24 lh-30 text-grayscale--500 mb-3'>
                    Redes Sociais
                  </h2>
                  <p className='fs-14 lh-18 text-grayscale--500  mb-4'>
                    Acesse as redes sociais da Inter Invest e do Inter Pig e fique sempre atualizado!
                  </p>
                </div>
                {
                fiquePorDentroJson.mobile.map((item: ItemProps) => (
                  <div key={item.id}>
                    <a
                      className='d-flex justify-content-between align-items-center'
                      href={item.link}
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_06',
                          section_name: 'Redes sociais Inter Invest',
                          element_action: 'click button',
                          element_name: item.title,
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <p className='fs-12 lh-14 fw-700 text-grayscale--500 mb-0'>{item.title}</p>
                      <OrangeIcon icon={item.icon} color='#FF7A00' size='MD' />
                    </a>
                    <hr style={{ margin: '15px 0' }} />
                  </div>
                ))
              }
              </div>
              <div className='d-none d-md-flex flex-wrap justify-content-between'>
                <div className='col-12 d-flex flex-column align-items-md-center px-0'>
                  <h2 className='fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 hl-xl-50 text-grayscale--500 mb-3'>
                    Redes sociais Inter Invest
                  </h2>
                  <p className='fs-md-14 lh-md-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
                    Siga as redes sociais da Inter Invest e mantenha-se atuaizado acerca das novidades do mercado
                  </p>
                </div>
                {
                  fiquePorDentroJson.desktop.map((item: ItemProps) => (
                    <S.SocialMediaCard key={item.id}>
                      <div className='d-flex align-items-center mb-md-5 mb-lg-0'>
                        <OrangeIcon icon={item.icon} color='#FF7A00' size='MD' />
                        <p className='fs-16 lh-20 fw-700 text-orange--extra mb-0 ml-2'>{item.title}</p>
                      </div>
                      <a
                        className='d-flex align-items-center mt-md-5 mt-lg-0'
                        target='_blank'
                        rel='noreferrer'
                        href={item.link}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_06',
                            section_name: 'Redes sociais Inter Invest',
                            element_action: 'click button',
                            element_name: item.iconTitle ? `${item.iconTitle} + ${item.title}` : 'null',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        <p className='fs-14 lh-16 text-orange--extra mb-0 mr-2'>{item.iconTitle}</p>
                        <OrangeIcon icon='arrow-right' color='#FF7A00' size='SM' />
                      </a>
                    </S.SocialMediaCard>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FiquePorDentro
